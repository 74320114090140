import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { Ranking } from '../../shared/components/select-ranking/select-ranking.component';
import { SelectItem } from '../../shared/models/select-item.model';

export enum ClusterType {
    Cluster1 = 'selectedComplementClusters1',
    Cluster2 = 'selectedComplementClusters2',
    Cluster3 = 'selectedComplementClusters3',
    Cluster4 = 'selectedComplementClusters4',
}

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private clusters: { [key in ClusterType]: BehaviorSubject<any> } = {
        [ClusterType.Cluster1]: new BehaviorSubject(null),
        [ClusterType.Cluster2]: new BehaviorSubject(null),
        [ClusterType.Cluster3]: new BehaviorSubject(null),
        [ClusterType.Cluster4]: new BehaviorSubject(null),
    };

    private budget: BehaviorSubject<string> = new BehaviorSubject('');
    private budgetV1: BehaviorSubject<string> = new BehaviorSubject('');
    private budgetV2: BehaviorSubject<string> = new BehaviorSubject('');
    private budgetV3: BehaviorSubject<string> = new BehaviorSubject('');
    private brand: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC1: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC2: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC3: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC4: BehaviorSubject<string> = new BehaviorSubject('');
    private brandC5: BehaviorSubject<string> = new BehaviorSubject('');
    private changeModal: BehaviorSubject<string> = new BehaviorSubject('');
    private changesData: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private complement: BehaviorSubject<string> = new BehaviorSubject('products[]=regular&');
    private complementClusters: BehaviorSubject<string> = new BehaviorSubject('clusters_ids[]=');
    private concoDiscount: BehaviorSubject<any> = new BehaviorSubject(null);
    private historicalTadReportDiscount: BehaviorSubject<any> = new BehaviorSubject(80);
    private selectedComplementClusters: BehaviorSubject<any> = new BehaviorSubject(null);
    private selectedComplementClusters2: BehaviorSubject<any> = new BehaviorSubject(null);
    private selectedComplementClusters3: BehaviorSubject<any> = new BehaviorSubject(null);
    private selectedComplementClusters4: BehaviorSubject<any> = new BehaviorSubject(null);
    private selectedComplementTiers: BehaviorSubject<any> = new BehaviorSubject(null);
    private selectedComplementZones: BehaviorSubject<any> = new BehaviorSubject(null);
    private selectedMonth: BehaviorSubject<any> = new BehaviorSubject(moment().month(10).date(1).format('YYYY-MM-DD'));
    private complementFlags: BehaviorSubject<string> = new BehaviorSubject('');
    private complementProducts: BehaviorSubject<string> = new BehaviorSubject('products[]=regular&products[]=premium&products[]=diesel&');
    private complementReasons: BehaviorSubject<string> = new BehaviorSubject('');
    private complementTiers: BehaviorSubject<string> = new BehaviorSubject('tiers[]=1&');
    private complementSchedules: BehaviorSubject<string> = new BehaviorSubject('');
    private complementZones: BehaviorSubject<string> = new BehaviorSubject('');
    private date: BehaviorSubject<any> = new BehaviorSubject(moment().format('YYYY-MM-DD'));
    private dateEnd: BehaviorSubject<any> = new BehaviorSubject(moment().endOf('month').format('YYYY-MM-DD'));
    private dateStart: BehaviorSubject<any> = new BehaviorSubject(moment().startOf('month').format('YYYY-MM-DD'));
    private daysAgo: BehaviorSubject<number> = new BehaviorSubject(30);
    private daysOptimumPrice: BehaviorSubject<number> = new BehaviorSubject(180);
    private daysOptimumPx: BehaviorSubject<number> = new BehaviorSubject(180);
    private daysAgoActual: BehaviorSubject<number> = new BehaviorSubject(1);
    private daysAgoChange: BehaviorSubject<number> = new BehaviorSubject(14);
    private daysAgoYesterday: BehaviorSubject<number> = new BehaviorSubject(14);
    private daysDashboard: BehaviorSubject<number> = new BehaviorSubject(30);
    private daysEvolution: BehaviorSubject<number> = new BehaviorSubject(30);
    private entity: BehaviorSubject<string> = new BehaviorSubject('');
    private entityText: BehaviorSubject<string> = new BehaviorSubject('');
    private group: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('group'));
    private headerTableComparative: BehaviorSubject<any> = new BehaviorSubject([]);
    private imgData1: BehaviorSubject<string> = new BehaviorSubject('');
    private imgData2: BehaviorSubject<string> = new BehaviorSubject('');
    private imgData3: BehaviorSubject<string> = new BehaviorSubject('');
    private imgData4: BehaviorSubject<string> = new BehaviorSubject('');
    private imgData5: BehaviorSubject<string> = new BehaviorSubject('');
    private imgData6: BehaviorSubject<string> = new BehaviorSubject('');
    private imgData7: BehaviorSubject<string> = new BehaviorSubject('');
    private imgData8: BehaviorSubject<string> = new BehaviorSubject('');
    private imgData: BehaviorSubject<string> = new BehaviorSubject('');
    private importants: BehaviorSubject<any> = new BehaviorSubject(null);
    private levelDiscount: BehaviorSubject<string> = new BehaviorSubject('9');
    private margin: BehaviorSubject<string> = new BehaviorSubject('purchase');
    private marginType: BehaviorSubject<string> = new BehaviorSubject('1');
    private markeType: BehaviorSubject<string> = new BehaviorSubject('');
    private monthsAgo: BehaviorSubject<any> = new BehaviorSubject(12);
    private monthBudgetSelected: BehaviorSubject<any> = new BehaviorSubject(moment().startOf('month').format('YYYY-MM-DD'));
    private nameStation: BehaviorSubject<string> = new BehaviorSubject('');
    private national: BehaviorSubject<string> = new BehaviorSubject('');
    private page: BehaviorSubject<string> = new BehaviorSubject('Monitor');
    private pageMetric: BehaviorSubject<string> = new BehaviorSubject('');
    private pageSend: BehaviorSubject<string> = new BehaviorSubject('');
    private period: BehaviorSubject<string> = new BehaviorSubject('week');
    private periodBudget: BehaviorSubject<string> = new BehaviorSubject('month');
    private periodBudgetSelected: BehaviorSubject<string> = new BehaviorSubject('month');
    private periodComparative: BehaviorSubject<number> = new BehaviorSubject(1);
    private periodDashboard: BehaviorSubject<string> = new BehaviorSubject('day');
    private periodEarning: BehaviorSubject<number> = new BehaviorSubject(1);
    private periodIndex: BehaviorSubject<string> = new BehaviorSubject(null);
    private periodOnePage: BehaviorSubject<string> = new BehaviorSubject('week');
    private productType: BehaviorSubject<string> = new BehaviorSubject('regular');
    private productTypeBudget: BehaviorSubject<string> = new BehaviorSubject('all');
    private productTypeSales: BehaviorSubject<string> = new BehaviorSubject('products');
    private productTypeAll: BehaviorSubject<string> = new BehaviorSubject('regular');
    private productTypeTotal: BehaviorSubject<string> = new BehaviorSubject('regular');
    private productTypeGasoline: BehaviorSubject<string> = new BehaviorSubject('all');
    private productGasoline: BehaviorSubject<string> = new BehaviorSubject('regular');
    private purchaseSource: BehaviorSubject<string> = new BehaviorSubject('all');
    private reason: BehaviorSubject<string> = new BehaviorSubject('');
    private region: BehaviorSubject<string> = new BehaviorSubject('');
    private ruleType: BehaviorSubject<string> = new BehaviorSubject('hard');
    private station: BehaviorSubject<any> = new BehaviorSubject('');
    private schedule: BehaviorSubject<any> = new BehaviorSubject('648147330fee9229ceb4182e');
    private scheduleSelected: BehaviorSubject<any> = new BehaviorSubject(1);
    private statusPhoto: BehaviorSubject<any> = new BehaviorSubject(0);
    private stationId: BehaviorSubject<any> = new BehaviorSubject('');
    private stationSelected: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('idStation'));
    private statistic: BehaviorSubject<string> = new BehaviorSubject('accum');
    private statisticBudget: BehaviorSubject<string> = new BehaviorSubject('0');
    private statisticSales: BehaviorSubject<string> = new BehaviorSubject('avg');
    private statisticEvolution: BehaviorSubject<string> = new BehaviorSubject('avg');
    private statisticTradeTracking: BehaviorSubject<string> = new BehaviorSubject('avg');
    private showSubdivison: BehaviorSubject<number> = new BehaviorSubject(0);
    private showSubsubdivison: BehaviorSubject<number> = new BehaviorSubject(0);
    private tableComparative: BehaviorSubject<any> = new BehaviorSubject([]);
    private tendency: BehaviorSubject<string> = new BehaviorSubject('avg_selling_price');
    private top: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private town: BehaviorSubject<string> = new BehaviorSubject('');
    private townText: BehaviorSubject<string> = new BehaviorSubject('');
    private typePx: BehaviorSubject<string> = new BehaviorSubject('min');
    private typeOnePage: BehaviorSubject<string> = new BehaviorSubject('');
    private valueType: BehaviorSubject<string> = new BehaviorSubject('');
    private variable: BehaviorSubject<string> = new BehaviorSubject('selling_price');
    private variableCrossReport: BehaviorSubject<string> = new BehaviorSubject('sales');
    private variableSecondCrossReport: BehaviorSubject<string> = new BehaviorSubject('');
    private variableBrand: BehaviorSubject<string> = new BehaviorSubject('num_stations');
    private variableDashboard: BehaviorSubject<string> = new BehaviorSubject('selling_price');
    private variableDashboardText: BehaviorSubject<string> = new BehaviorSubject('Precio de Venta');
    private variableEarning: BehaviorSubject<any> = new BehaviorSubject(1);
    private variableEvolution: BehaviorSubject<string> = new BehaviorSubject('selling_price');
    private variableSecondEvolution: BehaviorSubject<string> = new BehaviorSubject('purchase_price');
    private variablePriceIndex: BehaviorSubject<string> = new BehaviorSubject('quotient');
    private variablePxOpt: BehaviorSubject<string> = new BehaviorSubject('sales');
    private variableOnePage: BehaviorSubject<string> = new BehaviorSubject('volume');
    private topBottomVariableOnePage: BehaviorSubject<string> = new BehaviorSubject('');
    private week: BehaviorSubject<string> = new BehaviorSubject('');
    private weekSelected: BehaviorSubject<string> = new BehaviorSubject('');
    private weekBudgetSelected: BehaviorSubject<string> = new BehaviorSubject(moment().startOf('week').format('YYYY-MM-DD'));
    private weeksAgo: BehaviorSubject<any> = new BehaviorSubject(12);
    private yearBudgetSelected: BehaviorSubject<any> = new BehaviorSubject(moment().startOf('year').format('YYYY-MM-DD'));
    private zone: BehaviorSubject<string> = new BehaviorSubject(localStorage.getItem('idZone') || '');
    private zoneDashboard: BehaviorSubject<string> = new BehaviorSubject(null);
    private zoneDashboardText: BehaviorSubject<string> = new BehaviorSubject('');
    private zoneMarket: BehaviorSubject<string> = new BehaviorSubject('');
    private zoneMarketText: BehaviorSubject<string> = new BehaviorSubject('');
    private zoneOnePage: BehaviorSubject<string> = new BehaviorSubject('');
    private ranking: BehaviorSubject<Ranking[]> = new BehaviorSubject([]);
    private selectedRanking: BehaviorSubject<string> = new BehaviorSubject('');
    private selectedPercentage: BehaviorSubject<string> = new BehaviorSubject('');

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() { 
    }

    getSharedSelectedClusters(type: ClusterType): Observable<any> {
        return this.clusters[type].asObservable();
    }

    setNextSelectedClusters(type: ClusterType, value: any): void {
        this.clusters[type].next(value);
    }

    setRanking(rankings: Ranking[]): void {
        this.ranking.next(rankings);
    }

    get sharedPage() {
        return this.page.asObservable();
    }

    set nextPage(page: string) {
        this.page.next(page);
    }

    get sharedStatusPhoto() {
        return this.statusPhoto.asObservable();
    }

    set nextStatusPhoto(statusPhoto: string) {
        this.statusPhoto.next(statusPhoto);
    }

    get sharedTop() {
        return this.top.asObservable();
    }

    set nextTop(top: boolean) {
        this.top.next(top);
    }

    get sharedProductType() {
        return this.productType.asObservable();
    }

    set nextProductType(productType: string) {
        this.productType.next(productType);
    }

    get sharedProductTypeBudget() {
        return this.productTypeBudget.asObservable();
    }

    set nextProductTypeBudget(productTypeBudget: string) {
        this.productTypeBudget.next(productTypeBudget);
    }

    get sharedProductTypeSales() {
        return this.productTypeSales.asObservable();
    }

    set nextProductTypeSales(productTypeSales: string) {
        this.productTypeSales.next(productTypeSales);
    }


    get sharedProductTypeAll() {
        return this.productTypeAll.asObservable();
    }

    set nextProductTypeAll(productTypeAll: string) {
        this.productTypeAll.next(productTypeAll);
    }

    get sharedProductTypeTotal() {
        return this.productTypeTotal.asObservable();
    }

    set nextProductTypeTotal(productTypeTotal: string) {
        this.productTypeTotal.next(productTypeTotal);
    }

    get sharedProductTypeGasoline() {
        return this.productTypeGasoline.asObservable();
    }

    set nextProductTypeGasoline(productTypeGasoline: string) {
        this.productTypeGasoline.next(productTypeGasoline);
    }

    get sharedProductGasoline() {
        return this.productGasoline.asObservable();
    }

    set nextProductGasoline(productGasoline: string) {
        this.productGasoline.next(productGasoline);
    }

    get sharedPurchaseSource() {
        return this.purchaseSource.asObservable();
    }

    set nextPurchaseSource(purchaseSource: string) {
        this.purchaseSource.next(purchaseSource);
    }

    get sharedPeriod() {
        return this.period.asObservable();
    }

    set nextPeriod(period: string) {
        this.period.next(period);
    }

    get sharedPeriodBudget() {
        return this.periodBudget.asObservable();
    }

    set nextPeriodBudget(periodBudget: string) {
        this.periodBudget.next(periodBudget);
    }

    get sharedPeriodComparative() {
        return this.periodComparative.asObservable();
    }

    set nextPeriodComparative(periodComparative: number) {
        this.periodComparative.next(periodComparative);
    }

    get sharedPeriodEarning() {
        return this.periodEarning.asObservable();
    }

    set nextPeriodEarning(periodEarning: number) {
        this.periodEarning.next(periodEarning);
    }

    get sharedPeriodIndex() {
        return this.periodIndex.asObservable();
    }

    set nextPeriodIndex(periodIndex: string) {
        this.periodIndex.next(periodIndex);
    }

    get sharedPeriodOnePage() {
        return this.periodOnePage.asObservable();
    }

    set nextPeriodOnePage(periodOnePage: string) {
        this.periodOnePage.next(periodOnePage);
    }

    get sharedPeriodDashboard() {
        return this.periodDashboard.asObservable();
    }

    set nextPeriodDashboard(periodDashboard: string) {
        this.periodDashboard.next(periodDashboard);
    }

    get sharedTendency() {
        return this.tendency.asObservable();
    }

    set nextTendency(tendency: string) {
        this.tendency.next(tendency);
    }

    get sharedReason() {
        return this.reason.asObservable();
    }

    set nextReason(reason: string) {
        this.reason.next(reason);
    }

    get sharedStation() {
        return this.station.asObservable();
    }

    set nextStation(station: any) {
        this.station.next(station);
    }

    get sharedStationSelected() {
        return this.stationSelected.asObservable();
    }

    set nextStationSelected(stationSelected: any) {
        this.stationSelected.next(stationSelected);
    }
    get sharedComplement() {
        return this.complement.asObservable();
    }

    set nextComplement(complement: string) {
        this.complement.next(complement);
    }

    get sharedComplementProducts() {
        return this.complementProducts.asObservable();
    }

    set nextComplementProducts(complementProducts: string) {
        this.complementProducts.next(complementProducts);
    }

    get sharedComplementFlags() {
        return this.complementFlags.asObservable();
    }

    set nextComplementFlags(complementFlags: string) {
        this.complementFlags.next(complementFlags);
    }

    get sharedComplementReasons() {
        return this.complementReasons.asObservable();
    }

    set nextComplementReasons(complementReasons: string) {
        this.complementReasons.next(complementReasons);
    }

    get sharedComplementTiers() {
        return this.complementTiers.asObservable();
    }

    set nextComplementTiers(complementTiers: string) {
        this.complementTiers.next(complementTiers);
    }

    get sharedSelectedComplementTiers() {
        return this.selectedComplementTiers.asObservable();
    }

    set nextSelectedComplementTiers(selectedComplementTiers: any) {
        this.selectedComplementTiers.next(selectedComplementTiers);
    }

    get sharedComplementSchedules() {
        return this.complementSchedules.asObservable();
    }

    set nextComplementSchedules(complementSchedules: string) {
        this.complementSchedules.next(complementSchedules);
    }

    get sharedComplementZones() {
        return this.complementZones.asObservable();
    }

    set nextComplementZones(complementZones: string) {
        this.complementZones.next(complementZones);
    }

    get sharedSelectedComplementZones() {
        return this.selectedComplementZones.asObservable();
    }

    set nextSelectedComplementZones(selectedComplementZones: any) {
        this.selectedComplementZones.next(selectedComplementZones);
    }

    get sharedComplementClusters() {
        return this.complementClusters.asObservable();
    }

    set nextComplementClusters(complementClusters: any) {
        this.complementClusters.next(complementClusters);
    }

    get sharedSelectedComplementClusters() {
        return this.selectedComplementClusters.asObservable();
    }

    set nextSelectedComplementClusters(selectedComplementClusters: any) {
        this.selectedComplementClusters.next(selectedComplementClusters);
    }

    get sharedSelectedComplementClusters2() {
        return this.selectedComplementClusters2.asObservable();
    }

    set nextSelectedComplementClusters2(selectedComplementClusters2: any) {
        this.selectedComplementClusters2.next(selectedComplementClusters2);
    }

    get sharedSelectedComplementClusters3() {
        return this.selectedComplementClusters3.asObservable();
    }

    set nextSelectedComplementClusters3(selectedComplementClusters3: any) {
        this.selectedComplementClusters3.next(selectedComplementClusters3);
    }

    get sharedSelectedComplementClusters4() {
        return this.selectedComplementClusters4.asObservable();
    }

    set nextSelectedComplementClusters4(selectedComplementClusters4: any) {
        this.selectedComplementClusters4.next(selectedComplementClusters4);
    }

    get sharedVariable() {
        return this.variable.asObservable();
    }

    set nextVariable(variable: string) {
        this.variable.next(variable);
    }

    get sharedVariablePriceIndex() {
        return this.variablePriceIndex.asObservable();
    }

    set nextVariablePriceIndex(variablePriceIndex: string) {
        this.variablePriceIndex.next(variablePriceIndex);
    }

    get sharedVariableEvolution() {
        return this.variableEvolution.asObservable();
    }

    set nextVariableEvolution(variableEvolution: string) {
        this.variableEvolution.next(variableEvolution);
    }

    get sharedVariableSecondEvolution() {
        return this.variableSecondEvolution.asObservable();
    }

    set nextVariableSecondEvolution(variableSecondEvolution: string) {
        this.variableSecondEvolution.next(variableSecondEvolution);
    }

    get sharedVariableEarning() {
        return this.variableEarning.asObservable();
    }

    set nextVariableEarning(variableEarning: string) {
        this.variableEarning.next(variableEarning);
    }

    get sharedStatistic() {
        return this.statistic.asObservable();
    }

    set nextStatistic(statistic: string) {
        this.statistic.next(statistic);
    }

    get sharedStatisticBudget() {
        return this.statisticBudget.asObservable();
    }

    set nextStatisticBudget(statisticBudget: string) {
        this.statisticBudget.next(statisticBudget);
    }

    get sharedStatisticSales() {
        return this.statisticSales.asObservable();
    }
    
    set nextStatisticSales(statisticSales: string) {
        this.statisticSales.next(statisticSales);
    }

    get sharedStatisticEvolution() {
        return this.statisticEvolution.asObservable();
    }

    set nextStatisticEvolution(statisticEvolution: string) {
        this.statisticEvolution.next(statisticEvolution);
    }

    get sharedStatisticTradeTracking() {
        return this.statisticTradeTracking.asObservable();
    }

    set nextStatisticTradeTracking(statisticTradeTracking: string) {
        this.statisticTradeTracking.next(statisticTradeTracking);
    }

    get sharedVariableCrossReport() {
        return this.variableCrossReport.asObservable();
    }

    set nextVariableCrossReport(variableCrossReport: string) {
        this.variableCrossReport.next(variableCrossReport);
    }

    get sharedVariableSecondCrossReport() {
        return this.variableSecondCrossReport.asObservable();
    }

    set nextVariableSecondCrossReport(variableSecondCrossReport: string) {
        this.variableSecondCrossReport.next(variableSecondCrossReport);
    }

    get sharedVariableBrand() {
        return this.variableBrand.asObservable();
    }

    set nextVariableBrand(variableBrand: string) {
        this.variableBrand.next(variableBrand);
    }

    get sharedVariablePxOpt() {
        return this.variablePxOpt.asObservable();
    }

    set nextVariablePxOpt(variablePxOpt: string) {
        this.variablePxOpt.next(variablePxOpt);
    }

    get sharedVariableOnePage() {
        return this.variableOnePage.asObservable();
    }

    set nextVariableOnePage(variableOnePage: string) {
        this.variableOnePage.next(variableOnePage);
    }

    get sharedTopBottomVariableOnePage() {
        return this.topBottomVariableOnePage.asObservable();
    }

    set nextTopBottomVariableOnePage(variableOnePage: string) {
        this.topBottomVariableOnePage.next(variableOnePage);
    }

    get sharedVariableDashboard() {
        return this.variableDashboard.asObservable();
    }

    set nextVariableDashboard(variableDashboard: string) {
        this.variableDashboard.next(variableDashboard);
    }

    get sharedVariableDashboardText() {
        return this.variableDashboardText.asObservable();
    }

    set nextVariableDashboardText(variableDashboardText: string) {
        this.variableDashboardText.next(variableDashboardText);
    }

    get sharedNational() {
        return this.national.asObservable();
    }

    set nextNational(national: string) {
        this.national.next(national);
    }

    get sharedNameStation() {
        return this.nameStation.asObservable();
    }

    set nextNameStation(nameStation: string) {
        this.nameStation.next(nameStation);
    }

    get sharedMarkeType() {
        return this.markeType.asObservable();
    }

    set nextMarketType(markeType: string) {
        this.markeType.next(markeType);
    }

    get sharedEntity() {
        return this.entity.asObservable();
    }

    set nextEntity(entity: string) {
        this.entity.next(entity);
    }

    get sharedEntityText() {
        return this.entityText.asObservable();
    }

    set nextEntityText(entityText: string) {
        this.entityText.next(entityText);
    }

    get sharedBudget() {
        return this.budget.asObservable();
    }

    set nextBudget(budget: any) {
        this.budget.next(budget);
    }

    get sharedBudgetV1() {
        return this.budgetV1.asObservable();
    }

    set nextBudgetV1(budgetV1: any) {
        this.budgetV1.next(budgetV1);
    }

    get sharedBudgetV2() {
        return this.budgetV2.asObservable();
    }

    set nextBudgetV2(budgetV2: any) {
        this.budgetV2.next(budgetV2);
    }

    get sharedBudgetV3() {
        return this.budgetV3.asObservable();
    }

    set nextBudgetV3(budgetV3: any) {
        this.budgetV3.next(budgetV3);
    }

    get sharedZone() {
        return this.zone.asObservable()
    }

    get sharedZoneHeatMap() {
        return this.zone.asObservable().pipe(
            map(zone => zone || '')
        );
    }

    set nextZone(zone: any) {
        this.zone.next(zone);
    }


    get sharedZoneOnePage() {
        return this.zoneOnePage.asObservable()
    }

    set nextZoneOnePage(zone: any) {
        this.zoneOnePage.next(zone);
    }

    get sharedZoneDashboard() {
        return this.zoneDashboard.asObservable();
    }

    set nextZoneDashboard(zoneDashboard: any) {
        this.zoneDashboard.next(zoneDashboard);
    }

    get sharedZoneDashboardText() {
        return this.zoneDashboardText.asObservable();
    }

    set nextZoneDashboardText(zoneDashboardText: any) {
        this.zoneDashboardText.next(zoneDashboardText);
    }

    get sharedZoneMarket() {
        return this.zoneMarket.asObservable();
    }

    set nextZoneMarket(zoneMarket: string) {
        this.zoneMarket.next(zoneMarket);
    }

    get sharedZoneMarketText() {
        return this.zoneMarketText.asObservable();
    }

    set nextZoneMarketText(zoneMarketText: string) {
        this.zoneMarketText.next(zoneMarketText);
    }

    get sharedTown() {
        return this.town.asObservable();
    }

    set nextTown(town: string) {
        this.town.next(town);
    }

    get sharedTownText() {
        return this.townText.asObservable();
    }

    set nextTownText(townText: string) {
        this.townText.next(townText);
    }

    get sharedRegion() {
        return this.region.asObservable();
    }

    set nextRegion(region: string) {
        this.region.next(region);
    }

    get sharedBrand() {
        return this.brand.asObservable();
    }

    set nextBrand(brand: string) {
        this.brand.next(brand);
    }

    get sharedBrandC1() {
        return this.brandC1.asObservable();
    }

    set nextBrandC1(brandC1: string) {
        this.brandC1.next(brandC1);
    }

    get sharedBrandC2() {
        return this.brandC2.asObservable();
    }

    set nextBrandC2(brandC2: string) {
        this.brandC2.next(brandC2);
    }

    get sharedBrandC3() {
        return this.brandC3.asObservable();
    }

    set nextBrandC3(brandC3: string) {
        this.brandC3.next(brandC3);
    }

    get sharedBrandC4() {
        return this.brandC4.asObservable();
    }

    set nextBrandC4(brandC4: string) {
        this.brandC4.next(brandC4);
    }
    get sharedBrandC5() {
        return this.brandC5.asObservable();
    }

    set nextBrandC5(brandC5: string) {
        this.brandC5.next(brandC5);
    }

    get sharedChangesData() {
        return this.changesData.asObservable();
    }

    set nextChangesData(changesData: boolean) {
        this.changesData.next(changesData);
    }

    get sharedChangeModal() {
        return this.changeModal.asObservable();
    }

    set nextChangeModal(changeModal: string) {
        this.changeModal.next(changeModal);
    }

    get sharedDate() {
        return this.date.asObservable();
    }

    set nextDate(date: any) {
        this.date.next(date);
    }

    get sharedPeriodBudgetSelected() {
        return this.periodBudgetSelected.asObservable();
    }

    set nextPeriodBudgetSelected(periodBudgetSelected: any) {
        this.periodBudgetSelected.next(periodBudgetSelected);
    }

    get sharedDateStart() {
        return this.dateStart.asObservable();
    }

    set nextDateStart(dateStart: number) {
        this.dateStart.next(dateStart);
    }

    get sharedDateEnd() {
        return this.dateEnd.asObservable();
    }

    set nextDateEnd(dateEnd: number) {
        this.dateEnd.next(dateEnd);
    }

    get sharedWeeksAgo() {
        return this.weeksAgo.asObservable();
    }

    set nextSelectedMonth(selectedMonth: number) {
        this.selectedMonth.next(selectedMonth);
    }

    get sharedSelectedMonth() {
        return this.selectedMonth.asObservable();
    }

    set nextWeeksAgo(weeksAgo: number) {
        this.weeksAgo.next(weeksAgo);
    }

    get sharedMonthBudgetSelected() {
        return this.monthBudgetSelected.asObservable();
    }

    set nextMonthBudgetSelected(monthBudgetSelected: number) {
        this.monthBudgetSelected.next(monthBudgetSelected);
    }

    get sharedMonthsAgo() {
        return this.monthsAgo.asObservable();
    }

    set nextMonthsAgo(monthsAgo: number) {
        this.monthsAgo.next(monthsAgo);
    }

    get sharedDaysAgo() {
        return this.daysAgo.asObservable();
    }

    set nextDaysAgo(daysAgo: number) {
        this.daysAgo.next(daysAgo);
    }

    get sharedSchedule() {
        return this.schedule.asObservable();
    }

    set nextSchedule(schedule: any) {
        this.schedule.next(schedule);
    }

    get sharedScheduleSelected() {
        return this.scheduleSelected.asObservable();
    }

    set nextScheduleSelected(scheduleSelected: any) {
        this.scheduleSelected.next(scheduleSelected);
    }

    get sharedDaysEvolution() {
        return this.daysEvolution.asObservable();
    }

    set nextDaysEvolution(daysEvolution: number) {
        this.daysEvolution.next(daysEvolution);
    }

    get sharedDaysDashboard() {
        return this.daysDashboard.asObservable();
    }

    set nextDaysDashboard(daysDashboard: number) {
        this.daysDashboard.next(daysDashboard);
    }

    get sharedDaysOptimumPrice() {
        return this.daysOptimumPrice.asObservable();
    }

    set nextDaysOptimumPrice(daysOptimumPrice: number) {
        this.daysOptimumPrice.next(daysOptimumPrice);
    }

    get sharedDaysOptimumPx() {
        return this.daysOptimumPx.asObservable();
    }

    set nextDaysOptimumPx(daysOptimumPx: number) {
        this.daysOptimumPx.next(daysOptimumPx);
    }

    get sharedDaysAgoActual() {
        return this.daysAgoActual.asObservable();
    }

    set nextDaysAgoActual(daysAgoActual: number) {
        this.daysAgoActual.next(daysAgoActual);
    }

    get sharedDaysAgoChange() {
        return this.daysAgoChange.asObservable();
    }

    set nextDaysAgoChange(daysAgoChange: number) {
        this.daysAgoChange.next(daysAgoChange);
    }

    get sharedDaysAgoYesterday() {
        return this.daysAgoYesterday.asObservable();
    }

    set nextDaysAgoYesterday(daysAgoYesterday: number) {
        this.daysAgoYesterday.next(daysAgoYesterday);
    }

    get sharedStationId() {
        return this.stationId.asObservable();
    }

    set nextStationId(stationId: any) {
        this.stationId.next(stationId);
    }

    get sharedGroup() {
        return this.group.asObservable();
    }

    set nextGroup(group: any) {
        this.group.next(group);
    }

    get sharedPageMetric() {
        return this.pageMetric.asObservable();
    }

    set nextPageMetric(pageMetric: any) {
        this.pageMetric.next(pageMetric);
    }

    get sharedPageSend() {
        return this.pageSend.asObservable();
    }

    set nextPageSend(pageSend: any) {
        this.pageSend.next(pageSend);
    }

    get sharedWeek() {
        return this.week.asObservable();
    }

    set nextWeek(week: any) {
        this.week.next(week);
    }

    get sharedWeekSelected() {
        return this.weekSelected.asObservable();
    }

    set nextWeekSelected(weekSelected: any) {
        this.weekSelected.next(weekSelected);
    }

    get sharedWeekBudgetSelected() {
        return this.weekBudgetSelected.asObservable();
    }

    set nextWeekBudgetSelected(weekBudgetSelected: any) {
        this.weekBudgetSelected.next(weekBudgetSelected);
    }

    get sharedYearBudgetSelected() {
        return this.yearBudgetSelected.asObservable();
    }

    set nextYearBudgetSelected(yearBudgetSelected: any) {
        this.yearBudgetSelected.next(yearBudgetSelected);
    }    


    get sharedMargin() {
        return this.margin.asObservable();
    }

    set nextMargin(margin: any) {
        this.margin.next(margin);
    }

    get sharedMarginType() {
        return this.marginType.asObservable();
    }

    set nextMarginType(marginType: any) {
        this.marginType.next(marginType);
    }

    get sharedLevelDiscount() {
        return this.levelDiscount.asObservable();
    }

    set nextLevelDiscount(levelDiscount: any) {
        this.levelDiscount.next(levelDiscount);
    }

    get sharedConcoDiscount() {
        return this.concoDiscount.asObservable();
    }

    set nextConcoDiscount(concoDiscount: any) {
        this.concoDiscount.next(concoDiscount);
    }

    get sharedHistoricalTadReportDiscount() {
        return this.historicalTadReportDiscount.asObservable();
    }

    set nextHistoricalTadReportDiscount(historicalTadReportDiscount: string) {
        this.historicalTadReportDiscount.next(historicalTadReportDiscount);
    }

    get sharedHeaderTableComparative() {
        return this.headerTableComparative.asObservable();
    }

    set nextHeaderTableComparative(headerTableComparative: any) {
        this.headerTableComparative.next(headerTableComparative);
    }

    get sharedTableComparative() {
        return this.tableComparative.asObservable();
    }

    set nextTableComparative(tableComparative: any) {
        this.tableComparative.next(tableComparative);
    }

    get sharedImgData() {
        return this.imgData.asObservable();
    }

    set nextImgData(imgData: any) {
        this.imgData.next(imgData);
    }

    get sharedImgData1() {
        return this.imgData1.asObservable();
    }

    set nextImgData1(imgData1: any) {
        this.imgData1.next(imgData1);
    }

    get sharedImgData2() {
        return this.imgData2.asObservable();
    }

    set nextImgData2(imgData2: any) {
        this.imgData2.next(imgData2);
    }

    get sharedImgData3() {
        return this.imgData3.asObservable();
    }

    set nextImgData3(imgData3: any) {
        this.imgData3.next(imgData3);
    }

    get sharedImgData4() {
        return this.imgData4.asObservable();
    }

    set nextImgData4(imgData4: any) {
        this.imgData4.next(imgData4);
    }

    get sharedImgData5() {
        return this.imgData5.asObservable();
    }

    set nextImgData5(imgData5: any) {
        this.imgData5.next(imgData5);
    }

    get sharedImgData6() {
        return this.imgData6.asObservable();
    }

    set nextImgData6(imgData6: any) {
        this.imgData6.next(imgData6);
    }

    get sharedImgData7() {
        return this.imgData7.asObservable();
    }

    set nextImgData7(imgData7: any) {
        this.imgData7.next(imgData7);
    }

    get sharedImgData8() {
        return this.imgData8.asObservable();
    }

    set nextImgData8(imgData8: any) {
        this.imgData8.next(imgData8);
    }

    get sharedImportants() {
        return this.importants.asObservable();
    }

    set nextImportants(importants: any) {
        this.importants.next(importants);
    }

    get sharedTypePx() {
        return this.typePx.asObservable();
    }

    set nextTypePx(typePx: any) {
        this.typePx.next(typePx);
    }

    get sharedTypeOnePage() {
        return this.typeOnePage.asObservable();
    }

    set nextTypeOnePage(typeOnePage: any) {
        this.typeOnePage.next(typeOnePage);
    }

    get sharedShowSubdivison() {
        return this.showSubdivison.asObservable();
    }

    set nextShowSubdivison(showSubdivison: number) {
        this.showSubdivison.next(showSubdivison);
    }

    get sharedShowSubsubdivison() {
        return this.showSubsubdivison.asObservable();
    }

    set nextShowSubsubdivison(showSubsubdivison: number) {
        this.showSubsubdivison.next(showSubsubdivison);
    }

    set setSharedRanking(rankings: Ranking[]) {
        this.ranking.next(rankings);
    }

    get sharedRanking(): Observable<Ranking[]> {
        return this.ranking.asObservable();
    }

    set nextSelectedRanking(value: string) {
        this.selectedRanking.next(value);
    }

    get sharedSelectedRanking(): Observable<string> {
        return this.selectedRanking.asObservable();
    }

    set nextSelectedPercentage(percentage: string) {
        this.selectedPercentage.next(percentage);
    }

    get sharedSelectedPercentage(): Observable<string> {
        return this.selectedPercentage.asObservable();
    }

    get sharedValueType() {
        return this.valueType.asObservable();
    }

    set nextValueType(valueType: string) {
        this.valueType.next(valueType);
    }

    get sharedRule() {
        return this.ruleType.asObservable();
    }

    set nextRule(ruleType: string) {
        this.ruleType.next(ruleType);
    }

    resetMarketIntelligence() {
        this.page.next('');
        this.productType.next('regular');
        this.variable.next('selling_price');
        this.variableBrand.next('num_stations');
        this.national.next('');
        this.markeType.next('');
        this.zone.next(null);
        this.zoneMarket.next('');
        this.entity.next('');
        this.town.next('');
        this.brandC1.next('');
        this.brandC2.next('');
        this.brandC3.next('');
    }

    resetPanel() {
        setTimeout(() => {
            this.zone.next(null);
        }, 100);
    }

    resetSendPrices() {
        // this.schedule.next('648147330fee9229ceb4182e');
        // this.scheduleSelected.next(1);
        // this.zone.next(null);
        // this.complementClusters.next('clusters_ids[]=');
        // this.selectedComplementClusters.next([]);
    }

    resetFilters() {
        this.complementClusters.next('clusters_ids[]=');
        this.selectedComplementClusters.next([]);
        this.complementZones.next('clusters_ids[]=');
        this.selectedComplementZones.next([]);
    }
}