export const getBackgroundColorUtil = (value: number): string => {
  switch (true) {
    case value >= 1 && value <= 2:
      return 'blue';
    case value >= 3 && value <= 4:
      return 'green';
    case value >= 5 && value <= 6:
      return 'yellow';
    case value >= 7 && value <= 8:
      return 'orange';
    case value >= 9 && value <= 10:
      return 'red';
    default:
      return 'transparent';
  }
};

export const getFlowCategoryUtil = (value) => {
  switch (value) {
    case 4:
      return 'Medio Alto';
    case 3:
      return 'Medio';
    case 2:
      return 'Medio Bajo';
    case 1:
      return 'Bajo';
    default:
      return value >= 5 ? 'Alto' : '-';
  }
};

export const getSocioeconomicLevel = (value) => {
  switch (value) {
    case 7:
      return 'A/B';
    case 6:
      return 'C+';
    case 5:
      return 'C';
    case 4:
      return 'C-';
    case 3:
      return 'D+';
    case 2:
      return 'D';
    case 1:
      return 'E';
    default:
      return '-';
  }
};


// Función para procesar el JSON y estructurarlo
export const processJsonDataForRules = (jsonData: any) => {
  const result: any[] = [];

  // Recorremos cada zona
  for (const [zoneName, zoneData] of Object.entries(jsonData.data as { [key: string]: { stations: any[], total: any } })) {
    const zoneStations = zoneData.stations.map(stationData => {
      return {
        ...stationData.station,
        rules: stationData.rules,
      };
    });

    // Agregamos las estaciones de la zona
    result.push(...zoneStations);

    // Agregamos la zona como una estación consolidada
    result.push({
      _id: `zone`,
      cre: '',
      name: zoneName,
      brand: 'Zona Consolidada',
      zone: zoneName,
      entidad: '',
      ruleType: '',
      prices: zoneData.total,
      pricesRule: {},
      pricesDifference: {},
      success: {},
      rules: {},
    });
    // se agrega zona vacia para hacer los saltos de linea se reconoce por el id zone
    /* result.push({
      _id: 'zone-empty',
      cre: '',
      name: '-',
      brand: 'Zona Consolidada',
      zone: 'Zona Vacía',
      entidad: '',
      ruleType: '',
      prices: {},
      pricesRule: {},
      pricesDifference: {},
      success: {},
      rules: {},
    }); */

  }

  // Agregamos un ítem homologado con los totales generales
  result.push({
    _id: 'total',
    cre: '',
    name: 'TOTAL',
    brand: 'Consolidado',
    zone: 'Consolidado',
    entidad: '',
    ruleType: '',
    prices: jsonData.totalData,
    pricesRule: {},
    pricesDifference: {},
    success: {},
    rules: {},
  });

  return result;
}


export const filterCompetitorsByIds = (ids: string[], competitors: { id: string; name: string }[]) => {
  return competitors.filter(competitor => ids.includes(competitor.id));
};


export const getDefaultValueForRules = (fields: any[], fieldName: string): any => {
  const fieldItem = fields.find(item => item.field === fieldName);
  return fieldItem ? fieldItem.defaultValue : null;
};

export const getOptionsListForRules = (fields: any[], fieldName: string): any => {
  const fieldItem = fields.find(item => item.field === fieldName);
  return fieldItem ? fieldItem.list : [];
};

export const getFieldList = (
  itemsRules: any[],
  ruleType: string,
  optionValue: string,
  fieldName: string
): any[] => {
  // Busca el tipo de regla
  const rule = itemsRules.find(item => item.value === ruleType);

  if (rule) {
    // Busca la opción específica dentro del tipo de regla
    const option = rule.options.find(opt => opt.value === optionValue);

    if (option && option.fields) {
      // Busca el campo específico dentro de la opción
      const field = option.fields.find(f => f.field === fieldName);

      // Retorna el listado si existe, o un array vacío si no se encuentra
      return field?.list || [];
    }
  }

  return []; // Si no se encuentra nada, retorna un array vacío
};

export const getField = (
  itemsRules: any[],
  ruleType: string,
  optionValue: string,
  fieldName: string
): any | null => {
  // Busca el tipo de regla
  const rule = itemsRules.find(item => item.value === ruleType);

  if (rule) {
    // Busca la opción específica dentro del tipo de regla
    const option = rule.options.find(opt => opt.value === optionValue);

    if (option && option.fields) {
      // Busca el campo específico dentro de la opción
      const field = option.fields.find(f => f.field === fieldName);

      // Retorna el campo completo si existe, o null si no se encuentra
      return field || null;
    }
  }

  return null; // Si no se encuentra nada, retorna null
};


export const formatJsonToSendRules = (data: any): any => {
  const rules = data.composition.rules.map((rule, index) => {
    const cleanedOperations = rule.operations
      .map((op: any) => {
        // Eliminar propiedades que sean null dentro de cada objeto en operations
        const cleanedOp = Object.fromEntries(Object.entries(op).filter(([_, value]) => value !== null));
        return cleanedOp;
      })
      .filter(op => Object.keys(op).length > 0); // Eliminar objetos vacíos después de limpiar

    const formattedRule = {
      ...rule,
      competitors: rule.competitors.map((competitor: any) => competitor.id),
      priority: index + 1,
      hwmCompetitors: rule.hwmCompetitors === 'one' ? 'some' : rule.hwmCompetitors,
      operations: cleanedOperations.length === 1 && cleanedOperations[0].type === 'without' ? [] : cleanedOperations 
    };

    if (!formattedRule.operator) {
      delete formattedRule.operator;
    }

    return formattedRule;
  });

  return {
    ...data,
    composition: {
      type: rules.length === 1 ? 'simple' : 'compound',
      rules
    }
  };
};


export const transformRulesFormatToInput = (ruleData: any): any => {
  return {
      _id: ruleData?.station ?? null,
      cre: null, // No se proporciona en la entrada, lo dejamos en null
      name: null, // No se proporciona en la entrada
      brand: null, // No se proporciona en la entrada
      zone: null, // No se proporciona en la entrada
      entidad: null, // No se proporciona en la entrada
      ruleType: ruleData?.ruleType ?? null,
      prices: {
          [ruleData?.product]: ruleData?.currentPrice ? parseFloat(ruleData.currentPrice) : null
      },
      pricesRule: {
          [ruleData?.product]: ruleData?.composition?.rules?.[0]?.priceRule ?? null
      },
      pricesDifference: {
          [ruleData?.product]: ruleData?.composition?.rules?.[0]?.diffPriceRule
              ? parseFloat(ruleData.composition.rules[0].diffPriceRule)
              : null
      },
      success: {
          [ruleData?.product]: ruleData?.fulfill ?? null
      },
      rules: {
          [ruleData?.ruleType]: {
              [ruleData?.product]: {
                  ruleID: ruleData?._id ?? null,
                  priceRule: ruleData?.composition?.rules?.[0]?.priceRule ?? null,
                  checkPriceRule: ruleData?.composition?.rules?.[0]?.ruleCheck ?? null,
                  diffPriceRule: ruleData?.composition?.rules?.[0]?.diffPriceRule
                      ? parseFloat(ruleData.composition.rules[0].diffPriceRule)
                      : null,
                  ruleName: ruleData?.rule?.name ?? null,
                  ruleDescription: ruleData?.rule?.description ?? null,
                  marginSimulated: ruleData?.marginSimulated ?? null,
                  composition: ruleData?.composition?.rules?.map(comp => ({
                      rule: comp?.rule?._id ?? null,
                      competitors: comp?.competitors ?? [],
                      hwmCompetitors: comp?.hwmCompetitors ?? null,
                      operator: comp?.operator ?? null,
                      operations: comp?.operations?.map(op => ({
                          type: op?.type ?? null,
                          value: op?.value ?? null,
                          product: op?.product ?? null,
                          check: op?.check ?? null,
                          unit: op?.unit ?? null,
                          operator: op?.operator ?? null,
                          _id: op?._id ?? null
                      })) ?? [],
                      priority: comp?.priority ?? null,
                      _id: comp?.rule?._id ?? null
                  })) ?? []
              }
          }
      }
  };
};


