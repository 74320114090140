<div class="container">
    <div class="row flexi" style="padding-bottom: 10px !important; justify-content: space-between; align-items: center;">
        <h5 style="font-weight: bold; margin: 0;">
            {{stateForm=='edit'?'Editar':''}} Regla de Precio
        </h5>

        <button type="button" class="cancel bg-secondary text-white" (click)="disabledRule()" *ngIf="stateForm=='edit'">Inactivar</button>
    </div>


    <div class="row">

        <form [formGroup]="rulesForm" novalidate style="display: inline-block !important;width: 100%">
            <div class="row justify-content-center">
                <div class="col-12" style="padding: 0;">
                    <section class="container left-line mb-2 p-3" style="border: 1px solid gray;padding-bottom: 0 !important;">
                        <div class="row">
                            <div class="col-4" style="padding: 0 8px 0 0;">
                                <label>Nombre de la regla</label>
                                <input type="text" class="form-control" formControlName="name" />
                            </div>
                            <div class="col-4" style="padding: 0 8px 0 0;">
                                <label>Descripción de la regla</label>
                                <input type="text" class="form-control" formControlName="description" />
                            </div>
                            <div class="col-4" style="padding: 0;">
                                <label>Tipo de regla</label>
                                <select class="form-control" formControlName="ruleType" [attr.readonly]="true">
                                    <option *ngFor="let d of itemsRules" [value]="d.value">{{ d.name }}</option>
                                </select>


                            </div>
                        </div>



                    </section>

                </div>
                <div class="col-12" style="padding: 0;" formGroupName="composition">
                    <div formArrayName="rules">
                        <section class="container left-line mb-2 p-3" style="border: 1px solid gray; padding-bottom: 0 !important;" *ngFor="let rule of getRulesArray().controls; let i = index" [formGroupName]="i">

                            <div class="row" style="padding-bottom: 0;">
                                <div class="col-6" style="padding: 0;">
                                    <h5>{{ getRulesArray().at(i).get('operator')?.value === 'or' ? 'Definición' : 'Condición' }} {{ i + 1 }}</h5>
                                </div>
                                <div class="col-6 text-right">
                                    <button type="button" class="remove" (click)="removeRule(i)" *ngIf="i!=0">
                                        <i class="fa fa-minus" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-2" style="padding: 0 8px 0 0;">
                                    <label>Mi precio debe ser = al</label>
                                    <select class="form-control" formControlName="rule" (change)="selectRule($event.target.value, i)">
                                        <option *ngFor="let d of rulesList" [value]="d.value">{{ d.text }}</option>
                                    </select>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;" *ngIf="getFieldData(getRulesArray().at(i).get('rule')?.value,'hwmCompetitors')?.visible">
                                    <div class="form-group" style="margin-bottom: 30px;">
                                        <label>De</label>
                                        <select class="form-control" formControlName="hwmCompetitors" (change)="onHwmCompetitorsChange($event, i)">
                                            <option *ngFor="let d of getFieldListData(getRulesArray().at(i).get('rule')?.value,'hwmCompetitors')" value="{{d.value}}"> {{d.text}}</option>
                                        </select>
                                    </div>

                                    <ng-multiselect-dropdown *ngIf="getRulesArray().at(i).get('hwmCompetitors')?.value === 'one'" class="rule-single" id="operator-groups" [placeholder]="'Seleccionar Competidor'" [settings]="dropdownSingleSettings" [data]="competitorsListByStation" appendTo="body" formControlName="competitors" (onSelect)="onCompetitorSelect($event, i)" (onDeSelect)="onCompetitorDeselect($event, i)" (onSelectAll)="onSelectAllCompetitors($event, i)" (onDeSelectAll)="onDeselectAllCompetitors(i)" dropdownClass="rule-multi">
                                    </ng-multiselect-dropdown>

                                    <ng-multiselect-dropdown *ngIf="getRulesArray().at(i).get('hwmCompetitors')?.value === 'some'" class="rule-multi" id="operator-groups" [placeholder]="'Seleccionar Competidor(es)'" [settings]="dropdownMultiSettings" [data]="competitorsListByStation" appendTo="body" formControlName="competitors" (onSelect)="onCompetitorSelect($event, i)" (onDeSelect)="onCompetitorDeselect($event, i)" (onSelectAll)="onSelectAllCompetitors($event, i)" (onDeSelectAll)="onDeselectAllCompetitors(i)" dropdownClass="rule-multi">
                                    </ng-multiselect-dropdown>


                                </div>

                                <div class="col-2" style="padding: 0;" [formGroup]="getOperationGroup(i)" *ngIf="getFieldData(getRulesArray().at(i).get('rule')?.value,'operations.product')?.visible">
                                    <label>De</label>
                                    <select class="form-control" formControlName="product" [disabled]="isOperationNA(i)">
                                        <ng-container *ngFor="let d of getFieldListData(getRulesArray().at(i).get('rule')?.value,'operations.product')">
                                            <option *ngIf="d.value !== product" [value]="d.value">{{ d.text }}</option>
                                        </ng-container>
                                    </select>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;" [formGroup]="getOperationGroup(i)" *ngIf="getFieldData(getRulesArray().at(i).get('rule')?.value,'operations.type')?.visible">
                                    <label>Operación</label>
                                    <select formControlName="type" class="form-control" placeholder=">" (change)="onOperationChange($event, i)">
                                        <option *ngFor="let d of getFieldListData(getRulesArray().at(i).get('rule')?.value,'operations.type')" value="{{d.value}}"> {{d.text}}</option>
                                    </select>
                                </div>

                                <div class="col-2" style="padding: 0 8px 0 0;" [formGroup]="getOperationGroup(i)" *ngIf="!isOperationNA(i) && getFieldData(getRulesArray().at(i).get('rule')?.value,'operations.value')?.visible">
                                    <label>Esta cantidad</label>
                                    <input type="number" step=".01" class="form-control" formControlName="value" [disabled]="isOperationNA(i)" />
                                </div>

                                <div class="col-2" style="padding: 0;" [formGroup]="getOperationGroup(i)" *ngIf="!isOperationNA(i) && getFieldData(getRulesArray().at(i).get('rule')?.value,'operations.unit')?.visible">
                                    <label>Unidad</label>
                                    <select class="form-control" formControlName="unit" [disabled]="isOperationNA(i)">
                                        <option *ngFor="let d of getFieldListData(getRulesArray().at(i).get('rule')?.value,'operations.unit')" value="{{d.value}}"> {{d.text}}</option>
                                    </select>
                                </div>

                            </div>

                        </section>
                    </div>
                </div>

                <div class="col-12">
                    <div class="row" style="padding-top: 10px;">
                        <!-- Botones para añadir reglas -->
                        <div class="col-6" style="padding: 0 8px 0 0;">
                            <button *ngIf="isLastRule()==''||isLastRule()=='and'" type="button" class="addRule" (click)="addRule('AND')">
                                <i class="fa fa-plus-circle" aria-hidden="true" style="vertical-align: middle;"></i>
                                <span style="vertical-align: middle;">Añadir regla AND</span>
                            </button>

                            &nbsp;

                            <!-- Botón para Añadir Regla OR -->
                            <button *ngIf="isLastRule()=='' || isLastRule()=='or'" type="button" class="addRule" (click)="addRule('OR')">
                                <i class="fa fa-plus-circle" aria-hidden="true" style="vertical-align: middle;"></i>
                                <span style="vertical-align: middle;">Añadir regla OR</span>
                            </button>

                            <!-- Tooltips para productos -->
                            <epy-tooltip placement="top" style="display: inline-block; margin-left: 10px;">
                                <div class="epyc-tooltip" tabindex="0">
                                    <div class="tooltip-trigger">
                                        <span slot="trigger" class="difference"></span>
                                    </div>
                                    <div class="tooltip-content" role="tooltip">
                                        Al seleccionar este checkbox la regla creada se agregará al producto Regular
                                    </div>
                                </div>
                            </epy-tooltip>

                            <epy-tooltip placement="top" style="display: inline-block; margin-left: 10px;">
                                <div class="epyc-tooltip" tabindex="0">
                                    <div class="tooltip-trigger">
                                        <span slot="trigger" class="difference"></span>
                                    </div>
                                    <div class="tooltip-content" role="tooltip">
                                        Al seleccionar este checkbox la regla creada se agregará al producto Diesel
                                    </div>
                                </div>
                            </epy-tooltip>
                        </div>

                        <!-- Botones de acción -->
                        <div class="col-6 text-right" style="padding: 0;">
                            <button type="button" class="cancel" (click)="leaveComponent()">Cancelar</button>
                            <button type="button" class="save" (click)="registerRules()">{{stateForm=='edit'?'Actualizar':'Guardar'}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
    <!--   <div class="row" style="margin-top: 20px;">
        <app-table-loader style="width: 100%;"></app-table-loader>
    </div> -->

    <!-- <div class="row">
        <div class="col-6">
            <p>{{rulesForm.value|json}}</p>
        </div>
        <div class="col-6">
            <p>{{ruleItem|json}}</p>
        </div>
    </div>  -->


</div>