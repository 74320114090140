import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "src/app/modules/gasprecio/services/shared.service";

@Component({
  selector: 'app-select-rule-type',
  templateUrl: './select-rule-type.component.html',
  styleUrls: ['./select-rule-type.component.scss']
})
export class SelectRuleTypeComponent implements OnDestroy {
  public items: any[] = [
    { value: "hard", name: "Regla Dura" },
    { value: "soft", name: "Regla Blanda" },
    { value: "check", name: "Check" },
  ];

  public ruleSelected: any = "hard";
  ruleSelected$: Subscription;
  constructor(private sharedService: SharedService) {
    this.ruleSelected$ = sharedService.sharedRule.subscribe(
      (ruleSelected: string) => {
        if (this.ruleSelected !== ruleSelected) {
          this.ruleSelected = ruleSelected;
        }
      }
    );
  }

  ngOnDestroy() {
    this.ruleSelected$.unsubscribe();
  }

  changeVariable(event: any) {
    this.ruleSelected = event;
    this.sharedService.nextRule = this.ruleSelected;
  }
}
