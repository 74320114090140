import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import Swal from 'sweetalert2';
import { filterCompetitorsByIds, formatJsonToSendRules, getDefaultValueForRules, getField, getFieldList, getOptionsListForRules } from 'src/app/utils/commonUtils';

@Component({
  selector: 'app-price-rules-form',
  templateUrl: './price-rules-form.component.html',
  styleUrls: ['./price-rules-form.component.scss']
})
export class PriceRulesFormComponent implements OnInit, OnChanges {


  @Input() product: any;
  @Input() ruleItem: any;
  @Input() typeRuleSelected: any;
  @Input() stateForm: any;

  @Output() outComponent: EventEmitter<any> = new EventEmitter<any>();

  rulesForm: FormGroup;
  rulesList: any[] = [];

  public itemsRules: any[] = [
    {
      value: "hard",
      name: "Regla Dura",
      options: [
        {
          value: '5f91cec38257b357a7e78353', text: 'Precio Máximo',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5f91cd85c69fb65707d05320',
          text: 'Precio Óptimo',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '62c71c0e6958593f71527a96',
          text: 'Precio Estación',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'one',
              list: [
                { value: 'one', text: 'Un competidor' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5f91ce438257b357a7e78351',
          text: 'Precio Mínimo',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5f91d0741adb6159abb81a02',
          text: 'Precio Estación Mín.',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5f91d1431adb6159abb81a05',
          text: 'Precio Estación Prom.',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5fcfddd74dfe332667b0236f',
          text: 'Margen Mínimo',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'major_equal',
              list: [
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'comparison',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5ff67ce8d33a1863fb16a6ed',
          text: 'Margen Máximo',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'minor_equal',
              list: [

                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'comparison',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '64120a06b3577f98cbeb8676',
          text: 'Precio Diferencial Competencia',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5fa6dfac0215d0e7515991ab',
          text: 'Nivel de Precio',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'major_equal',
              list: [
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'comparison',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '66a11d079524f85624fa03c4',
          text: 'Precio TAR',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        }
      ]
    },
    {
      value: "soft",
      name: "Regla Blanda",
      options: [
        {
          value: '5f91cec38257b357a7e78353', text: 'Precio Máximo',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5f91cd85c69fb65707d05320',
          text: 'Precio Óptimo',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '62c71c0e6958593f71527a96',
          text: 'Precio Estación',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'one',
              list: [
                { value: 'one', text: 'Un competidor' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5f91ce438257b357a7e78351',
          text: 'Precio Mínimo',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5f91d0741adb6159abb81a02',
          text: 'Precio Estación Mín.',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5f91d1431adb6159abb81a05',
          text: 'Precio Estación Prom.',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5fcfddd74dfe332667b0236f',
          text: 'Margen Mínimo',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'major_equal',
              list: [
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'comparison',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5ff67ce8d33a1863fb16a6ed',
          text: 'Margen Máximo',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'minor_equal',
              list: [

                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'comparison',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '64120a06b3577f98cbeb8676',
          text: 'Precio Diferencial Competencia',
          fields: [
            {
              visible: true,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'some',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: true,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' },
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
                { value: 'without', text: 'N/A' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '5fa6dfac0215d0e7515991ab',
          text: 'Nivel de Precio',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'major_equal',
              list: [
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' },
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'comparison',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        },
        {
          value: '66a11d079524f85624fa03c4',
          text: 'Precio TAR',
          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: 'all',
              list: [
                { value: 'all', text: 'Todas las estaciones' },
                { value: 'some', text: 'Algunas estaciones' },
              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'add',
              list: [
                { value: 'add', text: '+' },
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'arithmetic',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            }
          ]
        }
      ]
    },
    {
      value: "check",
      name: "Check",
      options: [
        {
          value: '67a227bc0c2a5e33918eb0c9', text: 'Dif Magnitud', fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: null,
              list: [

              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: true,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: 'major_equal',
              list: [
                { value: 'major', text: '>' },
                { value: 'major_equal', text: '>=' },
                { value: 'equal', text: '=' },
                { value: 'minor_equal', text: '<=' },
                { value: 'minor', text: '<' }
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 0.01,
              list: []
            },
            {
              visible: false,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: 'price',
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'comparison',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            },
            {
              visible: false,
              label: '',
              field: 'operations.check',
              defaultValue: 'magnitude_diff',
              list: []
            },
            {
              visible: true,
              label: '',
              field: 'operations.product',
              defaultValue: null,
              list: [
                { value: 'regular', text: 'Regular' },
                { value: 'premium', text: 'Premium' },
                { value: 'diesel', text: 'Diesel' },
              ]
            }
          ]
        },
        {
          value: '67a227780c2a5e33918eb0c8', text: 'Dif Entera',

          fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: null,
              list: [

              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: false,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: null,
              list: [
                { value: 'add', text: '+' },
                { value: 'subtract', text: '-' }
              ]
            },
            {
              visible: false,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: null,
              list: []
            },
            {
              visible: false,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: null,
              list: [
                { value: 'price', text: '$' },
                { value: 'percent', text: '%' }
              ]
            },
            {
              visible: false,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: 'comparison',
              list: [
                { value: 'arithmetic', text: 'arithmetic' },
                { value: 'comparison', text: 'comparison' }
              ]
            },
            {
              visible: false,
              label: '',
              field: 'operations.check',
              defaultValue: 'int_diff',
              list: []
            },
            {
              visible: true,
              label: '',
              field: 'operations.product',
              defaultValue: null,
              list: [
                { value: 'diesel', text: 'Diesel' },
                { value: 'premium', text: 'Premium' },
                { value: 'regular', text: 'Regular' },
              ]
            }
          ]
        },  
        {
          value: '67ae271b0c2a5e33918eb12e', text: 'Redondeo', fields: [
            {
              visible: false,
              label: 'De',
              field: 'hwmCompetitors',
              defaultValue: null,
              list: [

              ]
            },
            {
              visible: false,
              label: '',
              field: 'competitors',
              defaultValue: [],
              list: []
            },
            {
              visible: false,
              label: 'Operación',
              field: 'operations.type',
              defaultValue: null,
              list: [
              ]
            },
            {
              visible: true,
              label: 'Esta cantidad',
              field: 'operations.value',
              defaultValue: 1,
              list: []
            },
            {
              visible: false,
              label: 'Unidad',
              field: 'operations.unit',
              defaultValue: null,
              list: [
              ]
            },
            {
              visible: true,
              label: 'Unidad',
              field: 'operations.operator',
              defaultValue: null,
              list: [
              ]
            },
            {
              visible: false,
              label: '',
              field: 'operations.check',
              defaultValue: null,
              list: []
            },
            {
              visible: false,
              label: '',
              field: 'operations.product',
              defaultValue: null,
              list: [
              ]
            }
          ]
        },                 
      ]
    }
  ];

  dropdownSingleSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Todas las estaciones',
    unSelectAllText: 'Ninguna estación',
    itemsShowLimit: 1,
    allowSearchFilter: false,
    maxHeight: 140
  };

  dropdownMultiSettings = {
    singleSelection: false, // Permite selección múltiple
    idField: 'id',
    textField: 'name',
    selectAllText: 'Seleccionar Todos',
    unSelectAllText: 'Deseleccionar Todos',
    itemsShowLimit: 1, // Límite de ítems visibles antes de colapsar en "+X más"
    allowSearchFilter: true, // Permite filtrar por búsqueda
    maxHeight: 140 // Control de altura para mejor visualización
  };

  competitorsListByStation: any[] = [];


  constructor(
    private gasprecioService: GasprecioService,
    private authenticationService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {

    /* NOTE NUEVO FORM */
    this.rulesForm = this.fb.group({
      _id: new FormControl(''),
      name: new FormControl(''),
      description: new FormControl(''),
      product: new FormControl(''),
      station: new FormControl(''),
      addRule: new FormControl('true'),
      ruleType: new FormControl(''),
      secondary: new FormControl(true),
      composition: this.fb.group({
        type: new FormControl('simple'),
        rules: this.fb.array([])
      })
    });


  }


  ngOnInit(): void {

    this.getCompetitors();

  }

  setFormRuler() {
    this.rulesForm.get('ruleType')?.valueChanges.subscribe((newType) => {

      this.typeRuleSelected = newType;
      this.updateRulesList();
    });

    this.rulesForm.patchValue({
      ruleType: this.typeRuleSelected,
      station: this.ruleItem._id,
      product: this.product
    });

    const ruleDetails = this.ruleItem?.rules[this.typeRuleSelected][this.product];

    const rulesArray = this.rulesForm.get('composition.rules') as FormArray;
    rulesArray.clear(); // Limpiar reglas previas

    if (this.stateForm === 'add') {
      //selecciona la primer regla por defautl por cada tipo de regla
      rulesArray.push(this.createRuleFormGroup(this.createRuleEmpty(null)));

    } else if (ruleDetails) {
      this.rulesForm.patchValue({
        _id: ruleDetails.ruleID,
        name: ruleDetails.ruleName,
        description: ruleDetails.ruleDescription,
      });

      // Agregar las reglas existentes si hay
      for (let r of ruleDetails.composition) {
        rulesArray.push(this.createRuleFormGroup(r));
      }
    }
  }

  getFieldListData(idRuler, fieldName): any[] {
    return getFieldList(this.itemsRules, this.typeRuleSelected, idRuler, fieldName)
  }
  getFieldData(idRuler, fieldName): any[] {
    return getField(this.itemsRules, this.typeRuleSelected, idRuler, fieldName)
  }



  createRuleEmpty(operator: any, index?: any): any {
    //obtener el pimer item
    const itemTypeRuleOption = this.itemsRules.find(rule => rule.value === this.typeRuleSelected)?.options[index ?? 0];

    return this.buildRuleStructure(itemTypeRuleOption, operator)
  }

  getAlternativeProduct(itemTypeRuleOption): string {
    // Obtener la lista de opciones
    const optionsList = getOptionsListForRules(itemTypeRuleOption.fields, "operations.product");

    // Filtrar y obtener el primer value que no sea igual a this.product
    const alternativeProduct = optionsList.find(option => option.value !== this.product);

    // Retornar el value si existe, de lo contrario, devolver this.product sin cambios
    return alternativeProduct ? alternativeProduct.value : null;
}


  buildRuleStructure(itemTypeRuleOption, operator) {
   
    const alternativeProduct = this.getAlternativeProduct(itemTypeRuleOption);

    // Agregar estructura vacía para la primera regla
    return {
      "rule": itemTypeRuleOption.value,
      "hwmCompetitors": getDefaultValueForRules(itemTypeRuleOption.fields, "hwmCompetitors") ?? null,
      "competitors": getDefaultValueForRules(itemTypeRuleOption.fields, "hwmCompetitors") == 'all' ? this.competitorsListByStation : getDefaultValueForRules(itemTypeRuleOption.fields, "competitors") ?? null,
      "operations": [
        {
          "type": getDefaultValueForRules(itemTypeRuleOption.fields, "operations.type") ?? null,
          "value": getDefaultValueForRules(itemTypeRuleOption.fields, "operations.value") ?? null,
          "unit": getDefaultValueForRules(itemTypeRuleOption.fields, "operations.unit") ?? null,
          "operator": getDefaultValueForRules(itemTypeRuleOption.fields, "operations.operator") ?? null,
          "check": getDefaultValueForRules(itemTypeRuleOption.fields, "operations.check") ?? null,
          "product": alternativeProduct ?? null,
        }
      ],
      "priority": null,
      "operator": operator
    }
  }


  getCompetitors() {
    this.gasprecioService.getCompetitors(this.ruleItem._id).subscribe((data: any) => {
      this.competitorsListByStation = [];
      data.forEach((element: any) => {
        element.selected = true;
        element['name'] = element['name'] ? this.gasprecioService.removeSADECV(element['name']) : '';
        element['brand'] = element['brand'] ? this.gasprecioService.removeSADECV(element['brand']) : '';
        element['brand'] = element['brand'] === 'Bp' ? 'BP' : element['brand'];
        let price = element[this.product];
        this.competitorsListByStation.push({ id: element['cre_id'], name: element.name + ' - ' + this.gasprecioService.formatDistance(element['distance']) + ' - (Precio: $ ' + price + ')' });
      });
      this.setFormRuler();
    });
  }


  ngOnChanges() {

  }


  createRuleFormGroup(rule: any = {}): FormGroup {
    return this.fb.group({
      rule: new FormControl(rule.rule || ''),
      hwmCompetitors: new FormControl(this.updateHwmCompetitorsIfInvalid(rule.rule, rule.hwmCompetitors) || ''),
      competitors: new FormControl(filterCompetitorsByIds(rule.competitors, this.competitorsListByStation) || []), // Ahora es un FormControl
      operations: this.fb.array(
        (rule.operations && rule.operations.length > 0)
          ? rule.operations.map((op: any) => this.createOperationFormGroup(op))
          : [this.createOperationFormGroup({ type: "without", check: null, product: null })] // Setear por defecto si está vacío
      ),
      priority: new FormControl(rule.priority || ''),
      operator: new FormControl(rule.operator || ''),
    });
  }

  updateHwmCompetitorsIfInvalid(idRule: string, selectedValue: string): string {
    // Encontrar el tipo de regla en itemsRules
    const ruleType = this.itemsRules.find(rule => rule.value === this.typeRuleSelected);

    if (!ruleType) {
      console.warn(`No se encontró el tipo de regla: ${this.typeRuleSelected}`);
      return selectedValue; // Retorna el valor original si no encuentra el tipo de regla
    }

    // Buscar la opción dentro de "options" con el valor igual a idRule
    const ruleOption = ruleType.options.find(option => option.value === idRule);

    if (!ruleOption) {
      console.warn(`No se encontró la opción con idRule: ${idRule} en ${this.typeRuleSelected}`);
      return selectedValue; // Retorna el valor original si no encuentra la opción
    }

    // Buscar el field "hwmCompetitors" dentro de "fields"
    const hwmCompetitorField = ruleOption.fields.find(field => field.field === "hwmCompetitors");

    if (!hwmCompetitorField) {
      return selectedValue; // Retorna el valor original si no existe "hwmCompetitors"
    }

    // Verificar si la lista de valores permitidos contiene el seleccionado
    const isValid = hwmCompetitorField.list.some((item: any) => item.value === selectedValue);

    // Si el valor seleccionado no está en la lista, retorna el defaultValue, sino retorna el mismo valor pasado
    return isValid ? selectedValue : hwmCompetitorField.defaultValue;
  }


  createOperationFormGroup(operation: any): FormGroup {
    return this.fb.group({
      type: new FormControl(operation.type),
      value: new FormControl(operation.value),
      unit: new FormControl(operation.unit),
      operator: new FormControl(operation.operator),
      check: new FormControl(operation.check || null),
      product: new FormControl(operation.product || null),
    });
  }

  leaveComponent() {
    this.outComponent.emit({ state: 'close' });
  }

  updateRulesList(): void {
    const selectedRuleType = this.rulesForm.get('ruleType')?.value;
    // Buscar el tipo de regla seleccionado en itemsRules
    const ruleCategory = this.itemsRules.find(rule => rule.value === selectedRuleType);
    // Limpiar la lista de reglas anterior
    this.rulesList = [];
    if (ruleCategory && ruleCategory.options) {
      // Asignar las nuevas opciones a rulesList
      this.rulesList = [...ruleCategory.options];
    }


  }

  getRulesArray(): FormArray {
    return this.rulesForm.get('composition.rules') as FormArray;
  }

  getOperationGroup(ruleIndex: number): FormGroup {
    return (this.getRulesArray().at(ruleIndex).get('operations') as FormArray).at(0) as FormGroup;
  }

  onCompetitorSelect(event: any, index: number) {
    const rulesArray = this.getRulesArray();
    const competitorsControl = rulesArray.at(index).get('competitors') as FormControl;

    const currentCompetitors = competitorsControl.value || [];
    if (!currentCompetitors.includes(event.id.toString())) {
      competitorsControl.setValue([...currentCompetitors]);
    }
  }

  onCompetitorDeselect(event: any, index: number) {
    const rulesArray = this.getRulesArray();
    const competitorsControl = rulesArray.at(index).get('competitors') as FormControl;

    const currentCompetitors = competitorsControl.value || [];
    competitorsControl.setValue(currentCompetitors.filter((id: string) => id !== event.id.toString()));
  }

  onSelectAllCompetitors(event: any, index: number) {
    const rulesArray = this.getRulesArray();
    const competitorsControl = rulesArray.at(index).get('competitors') as FormControl;

    const hwmCompetitorsControl = rulesArray.at(index).get('hwmCompetitors') as FormControl;

    if (hwmCompetitorsControl) {
      hwmCompetitorsControl.setValue('all');
    }

    const allIds = event.map((item: any) => item.id.toString());
    competitorsControl.setValue(allIds);
  }

  onDeselectAllCompetitors(index: number) {
    const rulesArray = this.getRulesArray();
    const competitorsControl = rulesArray.at(index).get('competitors') as FormControl;

    competitorsControl.setValue([]);
  }

  removeRule(index: number): void {
    const rulesArray = this.getRulesArray(); // Obtiene el FormArray de reglas
    if (rulesArray.length > 1) {
      rulesArray.removeAt(index); // Elimina la regla en el índice especificado
    } else {
      console.warn("Debe haber al menos una regla en el formulario.");
    }
  }

  isLastRule(): string {
    const rulesArray = this.getRulesArray();

    if (rulesArray.length === 0) {
      return ''; // Si no hay reglas, mostrar "Añadir regla AND"
    }

    const lastRule = rulesArray.at(rulesArray.length - 1);
    const operator = lastRule.get('operator')?.value;

    return operator;
  }

  addRule(type: 'AND' | 'OR'): void {
    const rulesArray = this.rulesForm.get('composition.rules') as FormArray;
    //selecciona la primer regla por defautl por cada tipo de regla
    rulesArray.push(this.createRuleFormGroup(this.createRuleEmpty(type.toLowerCase())));

  }

  selectRule(idRule: any, index: number): void {
    const itemTypeRuleOption = this.getRuleOption(idRule);

    const rulesArray = this.getRulesArray();
    const ruleGroup = rulesArray.at(index) as FormGroup;
    ruleGroup.patchValue(this.buildRuleStructure(itemTypeRuleOption, this.getOperatorFromRule(index)));

  }

  getOperatorFromRule(index: number): any {
    const rulesArray = this.getRulesArray();
    const ruleGroup = rulesArray.at(index);
    if (!ruleGroup) {
      console.warn(`No se encontró ninguna regla en el índice: ${index}`);
      return null;
    }
    return ruleGroup.get('operator')?.value || '';
  }

  getRuleOption(idRule: string): any {
    // Encontrar el tipo de regla en itemsRules
    const ruleType = this.itemsRules.find(rule => rule.value === this.typeRuleSelected);

    if (!ruleType) {
      return null;
    }

    // Buscar la opción dentro de "options" con el valor igual a idRule
    const ruleOption = ruleType.options.find(option => option.value === idRule);

    if (!ruleOption) {
      return null;
    }

    return ruleOption;
  }

  onHwmCompetitorsChange(event: any, index: number): void {
    const selectedValue = event.target.value;
    const rulesArray = this.getRulesArray();
    const rule = rulesArray.at(index);
    const competitorsControl = rule.get('competitors');

    if (selectedValue === 'all') {
      // Si el valor es 'all', asignar toda la lista de competidores
      competitorsControl?.setValue(this.competitorsListByStation.map(c => c));
    } else if (selectedValue === 'some') {
      // Si el valor es 'some', dejar vacío para que el usuario lo llene
      competitorsControl?.setValue([]);
    }
  }

  onOperationChange(event: any, index: number): void {
    const selectedValue = event.target.value;
    const operationGroup = this.getOperationGroup(index);

     // Determinar el operador basado en la selección
     const arithmeticOperations = ['add', 'subtract'];
     const newOperator = arithmeticOperations.includes(selectedValue) ? 'arithmetic' : 'comparison';
 
     // Actualizar el valor de "operator"
     operationGroup.get('operator')?.setValue(newOperator);

    if (selectedValue === 'without') {
      // operationGroup.get('type')?.disable();
      operationGroup.get('value')?.disable();
      operationGroup.get('unit')?.disable();
      operationGroup.get('operator')?.disable();
    } else {
      // operationGroup.get('type')?.enable();
      operationGroup.get('unit')?.enable();
      operationGroup.get('value')?.enable();
      operationGroup.get('operator')?.enable();
    }
  }

  isOperationNA(index: number): boolean {
    const operationType = this.getOperationGroup(index).get('type')?.value;
    return operationType === 'without';
  }

  async successUpdate() {
    Swal.fire({
      title: 'Regla de precio actualizada',
      text: 'Se ha actualizado la regla de precio con éxito.',
      icon: 'success',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
      preConfirm: () => {
        this.leaveComponent();
      }
    });
  }

  registerRules() {



    this.gasprecioService.mttoRule(formatJsonToSendRules(this.rulesForm.value), this.stateForm).subscribe({
      next: () => {
        this.successUpdate();
      }, error: () => {
        this.gasprecioService.displayMessage('error', 'Error', 'Revisa que hayas seleccionado al menos un competidor')
      }
    });

  }

  disabledRule() {
    this.gasprecioService.statusRule(this.rulesForm.value._id, 'deactive').subscribe({
      next: () => {
        this.successUpdate();
      }, error: () => {
        this.gasprecioService.displayMessage('error', 'Error', 'No se ha podido desactivar la regla seleccionada')
      }
    });
  }



}
